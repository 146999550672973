<template>
  <div class="edit-qa">
    <h2 class="text-2xl font-bold mb-4">Редактировать вопрос и ответ</h2>
    <form @submit.prevent="searchQuestion" v-if="!qaFound">
      <input v-model="searchQuery" placeholder="Введите точный текст вопроса" class="w-full p-2 mb-4 bg-gray-700 text-white rounded">
      <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded">Найти</button>
    </form>
    <form @submit.prevent="updateQA" v-else>
      <input v-model="editedQA.question" placeholder="Вопрос" class="w-full p-2 mb-4 bg-gray-700 text-white rounded">
      <textarea v-model="editedQA.answer" placeholder="Ответ" class="w-full p-2 mb-4 bg-gray-700 text-white rounded" rows="4"></textarea>
      <div class="flex justify-between">
        <button type="submit" class="bg-green-500 text-white px-4 py-2 rounded">Обновить</button>
        <button @click="showDeleteModal = true" type="button" class="bg-red-500 text-white px-4 py-2 rounded">Удалить</button>
      </div>
    </form>
    <p v-if="message" :class="{'text-green-500': !error, 'text-red-500': error}" class="mt-4">{{ message }}</p>

    <!-- Модальное окно подтверждения удаления -->
    <div v-if="showDeleteModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-gray-800 p-6 rounded-lg">
        <h3 class="text-xl font-bold mb-4">Подтвердите удаление</h3>
        <p class="mb-4">Вы уверены, что хотите удалить этот вопрос и ответ?</p>
        <div class="flex justify-end">
          <button @click="showDeleteModal = false" class="bg-gray-500 text-white px-4 py-2 rounded mr-2">Отмена</button>
          <button @click="deleteQA" class="bg-red-500 text-white px-4 py-2 rounded">Удалить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'EditQA',
  data() {
    return {
      searchQuery: '',
      qaFound: false,
      editedQA: {
        question: '',
        answer: ''
      },
      message: '',
      error: false,
      showDeleteModal: false
    }
  },
  methods: {
  async searchQuestion() {
    try {
      const response = await axios.get(`/api/qa/${encodeURIComponent(this.searchQuery)}`, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      this.editedQA = response.data;
      this.qaFound = true;
      this.message = '';
    } catch (error) {
      this.message = 'Вопрос не найден';
      this.error = true;
    }
  },
  async updateQA() {
    try {
      await axios.put(`/api/qa/${encodeURIComponent(this.searchQuery)}`, this.editedQA, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      this.message = 'Вопрос успешно обновлен';
      this.error = false;
    } catch (error) {
      this.message = 'Ошибка при обновлении вопроса';
      this.error = true;
    }
  },
  async deleteQA() {
    try {
      await axios.delete(`/api/qa/${encodeURIComponent(this.searchQuery)}`, {
        headers: { Authorization: localStorage.getItem('token') }
      });
      this.message = 'Вопрос успешно удален';
      this.error = false;
      this.qaFound = false;
      this.editedQA = { question: '', answer: '' };
      this.showDeleteModal = false;
    } catch (error) {
      this.message = 'Ошибка при удалении вопроса';
      this.error = true;
    }
  }
}
}
</script>