import axios from 'axios'
import store from '@/store'
import router from '@/router'

console.log('VUE_APP_API_URL:', process.env.VUE_APP_API_URL)

const baseURL = process.env.VUE_APP_API_URL || '/api'
console.log('Using baseURL:', baseURL)

const api = axios.create({
  baseURL: baseURL,
  timeout: 10000, // 10 seconds timeout
})

// Request interceptor
api.interceptors.request.use(
  config => {
    console.log('Request:', config.method.toUpperCase(), config.url)
    console.log('Request data:', config.data)

    // Добавляем токен авторизации к запросу
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = token
    }

    return config
  },
  error => {
    console.error('Request error:', error)
    return Promise.reject(error)
  }
)

// Response interceptor
api.interceptors.response.use(
  response => {
    console.log('Response:', response.status, response.config.url)
    console.log('Response data:', response.data)
    return response
  },
  error => {
    console.error('Response error:', error)
    if (error.response) {
      console.error('Error status:', error.response.status)
      console.error('Error data:', error.response.data)
    } else if (error.request) {
      console.error('No response received:', error.request)
    } else {
      console.error('Error setting up request:', error.message)
    }

    if (error.response && error.response.status === 401) {
      console.log('Unauthorized, logging out')
      store.dispatch('logout')
      router.push('/login')
    }
    return Promise.reject(error)
  }
)

export default api