import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/Home.vue'
import LoginPage from '@/views/Login.vue'
import RegisterPage from '@/views/Register.vue'
import store from '@/store'
import UploadQA from '@/views/UploadQA.vue' 
import AddQA from '@/views/AddQA.vue'
import ImportQA from '@/views/ImportQA.vue'
import EditQA from '@/views/EditQA.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage
  },
  {
    path: '/upload-qa',
    name: 'UploadQA',
    component: UploadQA
  },
  {
    path: '/import-qa',
    name: 'ImportQA',
    component: ImportQA,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-qa',
    name: 'AddQA',
    component: AddQA,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-qa',
    name: 'EditQA',
    component: EditQA,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router