<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full bg-gray-800 p-8 rounded-2xl shadow-lg">
      <div class="text-center mb-8">
        <h2 class="text-3xl font-extrabold text-white">
          Вход в аккаунт
        </h2>
      </div>
      <form class="space-y-6" @submit.prevent="handleLogin">
        <div class="space-y-4">
          <div class="relative">
            <input id="username" name="username" type="text" required
                   class="block w-full px-3 py-4 border border-gray-600 text-gray-300 placeholder-gray-400 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-300"
                   placeholder="Имя пользователя" v-model="username">
          </div>
          <div class="relative">
            <input id="password" name="password" type="password" required
                   class="block w-full px-3 py-4 border border-gray-600 text-gray-300 placeholder-gray-400 bg-gray-700 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-300"
                   placeholder="Пароль" v-model="password">
          </div>
        </div>
        
        <div class="flex items-center justify-between">
          <div class="text-sm">
            <a href="#" class="font-medium text-indigo-500 hover:text-indigo-400">
              Забыли имя пользователя / пароль?
            </a>
          </div>
        </div>

        <div>
          <button type="submit"
                  class="relative w-full flex justify-center py-4 px-4 border border-transparent text-lg font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-transform duration-300 transform hover:scale-105">
            Войти
          </button>
        </div>

        <div class="text-center text-sm text-gray-400">
          <p>Нет аккаунта? <a href="/register" class="font-medium text-indigo-500 hover:text-indigo-400">Зарегистрируйтесь сейчас</a></p>
        </div>
      </form>
      <p v-if="error" class="mt-4 text-center text-sm text-red-500 animate-pulse">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'LoginPage',
  data() {
    return {
      username: '',
      password: '',
      error: null
    }
  },
  methods: {
    ...mapActions(['login']),
    async handleLogin() {
      try {
        await this.login({ username: this.username, password: this.password });
        this.$router.push('/');
      } catch (error) {
        this.error = 'Ошибка входа. Пожалуйста, проверьте ваши учетные данные.';
        console.error('Login error:', error);
      }
    }
  }
}
</script>

<style scoped>
/* Эти стили можно вынести в глобальный файл стилей */
html, body, #app, .min-h-screen {
  min-height: 100vh;
  background-color: #1a202c;
  color: #e2e8f0;
  font-family: 'Roboto', sans-serif;
}

.bg-gray-900 {
  background-color: #1a202c;
}

.bg-gray-800 {
  background-color: #2d3748;
}

.text-gray-400 {
  color: #cbd5e0;
}

.text-gray-300 {
  color: #e2e8f0;
}

.bg-gray-700 {
  background-color: #4a5568;
}

.hover\:scale-105:hover {
  transform: scale(1.05);
}

.transition-transform {
  transition-property: transform;
}

.duration-300 {
  transition-duration: 300ms;
}

.focus\:outline-none:focus {
  outline: none;
}

.focus\:ring-2:focus {
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
}

.focus\:ring-indigo-500:focus {
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
}

.focus\:border-indigo-500:focus {
  border-color: rgba(99, 102, 241, 1);
}

.text-white {
  color: #ffffff;
}

.bg-indigo-600 {
  background-color: #5a67d8;
}

.hover\:bg-indigo-700:hover {
  background-color: #4c51bf;
}

.focus\:ring-offset-2:focus {
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
}

.focus\:ring-indigo-500:focus {
  box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
/* Стили для автозаполнения в Chrome */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #4a5568 inset !important;
  -webkit-text-fill-color: #e2e8f0 !important;
}

/* Убираем белые обводки при фокусе */
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #4a5568 inset !important;
  -webkit-text-fill-color: #e2e8f0 !important;
}

/* Убираем белые обводки при фокусе на обычных инпутах */
input:focus {
  background-color: #4a5568;
  color: #e2e8f0;
}
</style>