<template>
  <div class="import-export-qa">
    <h2 class="text-2xl font-bold mb-4">Импорт и экспорт вопросов и ответов</h2>
    
    <!-- Форма импорта -->
    <form @submit.prevent="uploadFile" class="space-y-4">
      <div>
        <label for="file-upload" class="block text-sm font-medium text-gray-300">
          Выберите JSON файл для импорта
        </label>
        <input
          id="file-upload"
          type="file"
          @change="handleFileChange"
          accept=".json"
          class="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        >
      </div>
      <div>
        <button
          type="submit"
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Импортировать
        </button>
      </div>
    </form>

    <!-- Кнопка экспорта -->
    <div class="mt-4">
      <button
        @click="exportQA"
        class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      >
        Экспортировать все вопросы и ответы
      </button>
    </div>

    <div v-if="message" class="mt-4 text-sm text-green-400">
      <p>{{ message }}</p>
      <p v-if="addedCount !== null">Добавлено новых вопросов: {{ addedCount }}</p>
      <p v-if="totalCount !== null">Всего вопросов в файле: {{ totalCount }}</p>
    </div>
    <p v-if="error" class="mt-4 text-sm text-red-400">{{ error }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ImportExportQA',
  data() {
    return {
      file: null,
      message: '',
      error: '',
      addedCount: null,
      totalCount: null
    }
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log('File content:', e.target.result);
        try {
          JSON.parse(e.target.result);
          console.log('JSON is valid');
        } catch (error) {
          console.error('Invalid JSON:', error);
        }
      };
      reader.readAsText(this.file);
    },
    async uploadFile() {
      if (!this.file) {
        this.error = 'Пожалуйста, выберите файл';
        return;
      }

      const formData = new FormData();
      formData.append('file', this.file);

      try {
        const response = await axios.post('/api/upload-qa', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token')
          }
        });
        console.log('Response:', response.data);
        this.message = response.data.message;
        this.addedCount = response.data.added;
        this.totalCount = response.data.total;
        this.error = '';
      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        this.error = error.response?.data?.error || 'Произошла ошибка при загрузке файла';
        this.message = '';
        this.addedCount = null;
        this.totalCount = null;
      }
    },
    async exportQA() {
      try {
        const response = await axios.get('/api/export-qa', {
          headers: {
            'Authorization': localStorage.getItem('token')
          },
          responseType: 'blob' // Важно для получения файла
        });
        
        // Создаем ссылку для скачивания файла
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'qa_export.json');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        this.message = 'Экспорт успешно завершен';
        this.error = '';
      } catch (error) {
        console.error('Export error:', error);
        this.error = 'Произошла ошибка при экспорте данных';
        this.message = '';
      }
    }
  }
}
</script>

<style scoped>
.import-export-qa {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #1f2937; /* bg-gray-800 */
  border-radius: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
</style>