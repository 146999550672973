<template>
  <div class="search-component">
    <div id="input-container" class="border p-4 mb-4 w-full text-xl bg-gray-800 border-gray-700 rounded shadow">
      <div class="bg-gray-800 p-4 rounded shadow relative">
        <input
          v-model="localSearchQuery"
          @input="handleInput"
          @focus="handleFocus"
          @click="clearInput"
          type="text"
          placeholder="Введите свой вопрос..."
          class="border p-2 mb-4 w-full bg-gray-700 border-gray-600 text-gray-100"
          ref="searchInput"
        >
      </div>
      <div id="results-container" class="space-y-4" v-if="shouldShowResults">
        <div v-for="result in filteredResults" :key="result.question" class="p-4 bg-gray-800 border-gray-700 rounded shadow fade-in slide-down card">
          <h2 class="text-2xl font-bold text-gray-100">{{ result.question }}</h2>
          <div v-if="hasMultipleAnswers(result.answer)" class="mt-2">
            <ul class="list-disc list-inside">
              <li v-for="(answer, index) in formatAnswers(result.answer)" :key="index" class="text-green-400 font-medium">
                {{ answer }}
              </li>
            </ul>
          </div>
          <p v-else class="mt-2 text-green-400 font-medium">{{ result.answer }}</p>
        </div>
      </div>
      <div v-else-if="localSearchQuery && !isLoading && filteredResults.length === 0" class="text-gray-400">
        Нет результатов для "{{ localSearchQuery }}"
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'SearchComponent',
  data() {
    return {
      localSearchQuery: '',
      isLoading: false,
    }
  },
  computed: {
    ...mapState(['searchResults']),
    filteredResults() {
      if (!this.localSearchQuery) return [];
      const query = this.localSearchQuery.toLowerCase();
      return this.searchResults.filter(result =>
        result.question.toLowerCase().includes(query) ||
        result.answer.toLowerCase().includes(query)
      );
    },
    shouldShowResults() {
      return this.localSearchQuery && this.filteredResults.length > 0;
    }
  },
  methods: {
    ...mapActions(['searchQA']),
    async handleInput() {
      this.isLoading = true;
      this.$store.commit('setSearchQuery', this.localSearchQuery);
      try {
        await this.searchQA();
      } catch (error) {
        console.error('Search failed:', error);
      } finally {
        this.isLoading = false;
      }
    },
    handleFocus(event) {
      event.target.select();
    },
    clearInput() {
      if (this.localSearchQuery) {
        this.localSearchQuery = '';
        this.$store.commit('setSearchQuery', '');
      }
    },
    formatAnswers(answer) {
      // Разделяем ответы по тегу <br>
      const answers = answer.split('<br>');
      // Удаляем пустые строки и лишние пробелы
      return answers.map(a => a.trim()).filter(a => a.length > 0);
    },
    hasMultipleAnswers(answer) {
      return answer.includes('<br>');
    }
  },
  mounted() {
    this.$nextTick(() => {
      const inputElement = this.$refs.searchInput;
      if (inputElement) {
        inputElement.focus();
      }
    });
  }
}
</script>

<style scoped>
.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}
.slide-down {
  animation: slideDown 0.5s ease-in-out;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes slideDown {
  from { transform: translateY(-20px); }
  to { transform: translateY(0); }
}
.card {
  border: 1px solid #4a5568;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
}
.list-disc {
  list-style-type: disc;
  padding-left: 1.5em;
}
.list-inside {
  list-style-position: inside;
}
</style>