<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full bg-gray-800 p-8 rounded-2xl shadow-lg">
      <h2 class="text-3xl font-extrabold text-white mb-6">Загрузка вопросов и ответов</h2>
      <form @submit.prevent="uploadFile" class="space-y-6">
        <div>
          <label for="file-upload" class="block text-sm font-medium text-gray-300">
            Выберите JSON файл
          </label>
          <input 
            id="file-upload" 
            type="file" 
            @change="handleFileChange" 
            accept=".json"
            class="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
        </div>
        <div>
          <button 
            type="submit"
            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Загрузить
          </button>
        </div>
      </form>
      <p v-if="message" class="mt-4 text-sm text-green-400">{{ message }}</p>
      <p v-if="error" class="mt-4 text-sm text-red-400">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UploadQA',
  data() {
    return {
      file: null,
      message: '',
      error: ''
    }
  },
  methods: {
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    async uploadFile() {
      if (!this.file) {
        this.error = 'Пожалуйста, выберите файл';
        return;
      }

      const formData = new FormData();
      formData.append('file', this.file);

      try {
        const response = await axios.post('/api/upload-qa', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        this.message = response.data.message;
        this.error = '';
      } catch (error) {
        this.error = error.response?.data?.error || 'Произошла ошибка при загрузке файла';
        this.message = '';
      }
    }
  }
}
</script>