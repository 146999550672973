import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import './assets/styles/global.css'
import api from './api/axios'

// Check for token on app start
const token = localStorage.getItem('token')
if (token) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`
  store.commit('setToken', token)
  store.commit('setLoggedIn', true)
}

const app = createApp(App)
app.config.globalProperties.$api = api
app.use(store).use(router).mount('#app')