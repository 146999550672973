<template>
  <div class="add-qa">
    <h2 class="text-2xl font-bold mb-4">Добавить вопрос и ответ</h2>
    <form @submit.prevent="addQA" class="space-y-4">
      <div>
        <label for="question" class="block text-sm font-medium text-gray-300">Вопрос</label>
        <input
          id="question"
          v-model="question"
          type="text"
          required
          class="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
        >
      </div>
      <div v-for="(answer, index) in answers" :key="index">
        <label :for="'answer'+index" class="block text-sm font-medium text-gray-300">Ответ {{ index + 1 }}</label>
        <div class="flex mt-1">
          <input
            :id="'answer'+index"
            v-model="answers[index]"
            type="text"
            required
            class="block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
          <button
            v-if="index === answers.length - 1"
            @click.prevent="addAnswer"
            class="ml-2 px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            +
          </button>
          <button
            v-if="answers.length > 1"
            @click.prevent="removeAnswer(index)"
            class="ml-2 px-3 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            -
          </button>
        </div>
      </div>
      <div>
        <button
          type="submit"
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Добавить
        </button>
      </div>
    </form>
    <div v-if="message" class="mt-4 text-sm" :class="{'text-green-400': !error, 'text-red-400': error}">
      {{ message }}
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'AddQA',
  data() {
    return {
      question: '',
      answers: [''],
      message: '',
      error: false
    }
  },
  methods: {
    addAnswer() {
      this.answers.push('');
    },
    removeAnswer(index) {
      if (this.answers.length > 1) {
        this.answers.splice(index, 1);
      }
    },
    async addQA() {
      try {
        const response = await axios.post('/api/add-qa', {
          question: this.question,
          answer: this.answers.join('<br>')
        }, {
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        });
        this.message = response.data.message;
        this.error = false;
        if (response.data.added) {
          this.question = '';
          this.answers = [''];
        }
      } catch (error) {
        this.message = error.response?.data?.error || 'Произошла ошибка при добавлении вопроса и ответа';
        this.error = true;
      }
    }
  }
}
</script>

<style scoped>
.add-qa {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
}
</style>