import { createStore } from 'vuex'
import api from '@/api/axios'

export default createStore({
  state: {
    isLoggedIn: false,
    token: localStorage.getItem('token') || null,
    qaData: {},
    searchQuery: '',
    searchResults: [],
  },
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value
    },
    setToken(state, token) {
      state.token = token
      if (token) {
        localStorage.setItem('token', token)
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`
      } else {
        localStorage.removeItem('token')
        delete api.defaults.headers.common['Authorization']
      }
    },
    setQAData(state, data) {
      state.qaData = data
    },
    setSearchQuery(state, query) {
      state.searchQuery = query
    },
    setSearchResults(state, results) {
      state.searchResults = results
    },
  },
  actions: {
    async checkAuth({ commit, dispatch }) {
      const token = localStorage.getItem('token')
      if (token) {
        commit('setToken', token)
        commit('setLoggedIn', true)
        try {
          await dispatch('fetchQAData')
        } catch (error) {
          console.error('Error during auth check:', error)
          dispatch('logout')
        }
      }
    },
    async login({ commit, dispatch }, { username, password }) {
      try {
        const response = await api.post('/login', { username, password })
        const token = response.data.token
        commit('setToken', token)
        commit('setLoggedIn', true)
        await dispatch('fetchQAData')
        return true
      } catch (error) {
        console.error('Login failed:', error)
        throw error
      }
    },
    async register({ dispatch }, { username, password }) {
      try {
        await api.post('/register', { username, password })
        return dispatch('login', { username, password })
      } catch (error) {
        console.error('Registration failed:', error)
        throw error
      }
    },
    logout({ commit }) {
      commit('setLoggedIn', false)
      commit('setToken', null)
      commit('setQAData', {})
      commit('setSearchQuery', '')
      commit('setSearchResults', [])
    },
    async fetchQAData({ commit }) {
      try {
        const response = await api.get('/qa')
        commit('setQAData', response.data)
      } catch (error) {
        console.error('Error fetching QA data:', error)
        if (error.response && error.response.status === 401) {
          commit('setLoggedIn', false)
          commit('setToken', null)
        }
        throw error
      }
    },
    async searchQA({ commit, state }) {
      try {
        const response = await api.get(`/qa?query=${encodeURIComponent(state.searchQuery)}`)
        commit('setSearchResults', Object.values(response.data))
      } catch (error) {
        console.error('Error searching QA:', error)
        if (error.response && error.response.status === 401) {
          commit('setLoggedIn', false)
          commit('setToken', null)
        }
        commit('setSearchResults', [])
        throw error
      }
    },
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    token: state => state.token,
    searchResults: state => state.searchResults,
  }
})