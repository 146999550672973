<template>
    <div id="app" class="min-h-screen bg-gray-900 text-white">
      <div class="container mx-auto px-4 py-8">
        <nav v-if="isLoggedIn" class="mb-8">
          <router-link to="/" class="text-blue-400 hover:text-blue-300 mr-4">Home</router-link>
          <router-link to="/import-qa" class="text-blue-400 hover:text-blue-300 mr-4">Импорт/Экспорт</router-link>
          <router-link to="/add-qa" class="text-blue-400 hover:text-blue-300 mr-4">Добавить Запись</router-link>
          <router-link to="/edit-qa" class="text-blue-400 hover:text-blue-300 mr-4">Редактировать вопросы</router-link>
          <a href="/api-docs.html" target="_blank" class="text-blue-400 hover:text-blue-300 mr-4">Документация</a>
          <a @click="logout" class="text-blue-400 hover:text-blue-300 cursor-pointer">Выйти</a>
        </nav>
        <nav v-else class="mb-8">
          <router-link to="/login" class="text-blue-400 hover:text-blue-300 mr-4">Войти</router-link>
          <router-link to="/register" class="text-blue-400 hover:text-blue-300">Регистрация</router-link>
        </nav>
        <router-view></router-view>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex'
  
  export default {
    name: 'App',
    computed: {
      ...mapGetters(['isLoggedIn'])
    },
    methods: {
      ...mapActions(['checkAuth', 'logout'])
    },
    mounted() {
      this.checkAuth()
    }
  }
  </script>

<style>
.hidden {
            display: none;
        }
        .fade-in {
            animation: fadeIn 0.5s ease-in-out;
        }
        .fade-out {
            animation: fadeOut 0.5s ease-in-out;
        }
        .slide-down {
            animation: slideDown 0.5s ease-in-out;
        }
        .slide-up {
            animation: slideUp 0.5s ease-in-out;
        }
        @keyframes fadeIn {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
        @keyframes fadeOut {
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        }
        @keyframes slideDown {
            from {
                transform: translateY(-20px);
            }
            to {
                transform: translateY(0);
            }
        }
        @keyframes slideUp {
            from {
                transform: translateY(0);
            }
            to {
                transform: translateY(-20px);
            }
        }
        #results-container {
            margin-top: 1rem;
        }
        .card {
            border: 1px solid #4a5568;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
        }
</style>